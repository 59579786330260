import getApiResponse from "../index";

const getCategoriesApi = async ({ page = 1, count = 25 }) => {
  const result = await getApiResponse({
    url: `/category`,
    method: "get",
    otherParams: {
      page,
      count,
    },
  });

  if (result.APIFailed) return null;
  return result.data;
};

export default getCategoriesApi;

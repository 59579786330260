const { useEffect } = require("react");

function useOnClickOutsize(ref, handler) {
  useEffect(() => {
    if (!ref.current) return;

    const listener = document.addEventListener("click", (event) => {
      const isClickInside = ref.current?.contains(event.target);
      if (isClickInside) handler(true);
      else handler(false);
    });

    return () => document.removeEventListener("click", listener);
  }, [ref, handler]);
}

export default useOnClickOutsize;

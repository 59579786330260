import { lazy } from "react";

const Home = lazy(() => import("../app/home/Home"));
const Library = lazy(() => import("../app/library/Library"));
const Shop = lazy(() => import("../app/shop/Shop"));
const SingleBookDetail = lazy(() =>
  import("../app/singleBookDetail/SingleBookDetail")
);
const SelectedBuyer = lazy(() =>
  import(
    "../app/singleBookDetail/bookDetails/buyNowSection/selectedBuyer/SelectedBuyer"
  )
);
const SelectedBorrower = lazy(() =>
  import(
    "../app/singleBookDetail/bookDetails/borrowSection/selectedBorrower/SelectedBorrower"
  )
);
const Account = lazy(() => import("../app/account/Account"));
const BookByCategory = lazy(() => import("../app/category/BookByCategory"));
const BooksByType = lazy(() => import("../app/booksByType/BooksByType"));
const Search = lazy(() => import("../app/search/Search"));
const BecomeSeller = lazy(() =>
  import("../app/applyForms/becomeSeller/BecomeSeller")
);
const BecomeLibrarian = lazy(() =>
  import("../app/applyForms/becomeLibrarian/BecomeLibrarian")
);
const ApplyLibraryMembership = lazy(() =>
  import("../app/applyForms/applyLibraryMembership/ApplyLibraryMembership")
);
const SelectedPackage = lazy(() =>
  import(
    "../app/applyForms/applyLibraryMembership/selectedPackage/SelectedPackage"
  )
);
const PackagePayment = lazy(() =>
  import(
    "../app/applyForms/applyLibraryMembership/selectedPackage/packagePayment/PackagePayment"
  )
);

const Privacy = lazy(() => import("../app/privacy/Privacy"));

const appRoutes = [
  {
    id: "home",
    path: "/",
    exact: true,
    component: Home,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },
  {
    id: "shop",
    path: "/shop",
    exact: true,
    component: Shop,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },

  {
    id: "library",
    path: "/library",
    exact: true,
    component: Library,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },

  {
    id: "singleBook",
    path: "/book/:id/:section",
    exact: true,
    component: SingleBookDetail,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },
  {
    id: "singleBookBuy",
    path: "/book/:id/:section/seller/:vendorId",
    exact: true,
    component: SelectedBuyer,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },
  {
    id: "singleBookRent",
    path: "/book/:id/:section/librarian/",
    exact: true,
    component: SelectedBorrower,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },

  {
    id: "account",
    path: "/account/:section/:sectionOption",
    exact: true,
    component: Account,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "category",
    path: "/books/category/:id",
    exact: true,
    component: BookByCategory,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },

  {
    id: "book-by-type",
    path: "/books-by-type/:id",
    exact: true,
    component: BooksByType,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },

  {
    id: "search",
    path: "/search",
    exact: true,
    component: Search,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },
  {
    id: "becomeSeller",
    path: "/become-seller",
    exact: true,
    component: BecomeSeller,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "becomeLibrarian",
    path: "/become-librarian",
    exact: true,
    component: BecomeLibrarian,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "applyLibraryMembership",
    path: "/apply-library-membership",
    exact: true,
    component: ApplyLibraryMembership,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "applyLibraryMembership",
    path: "/apply-library-membership/:id",
    exact: true,
    component: SelectedPackage,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },
  {
    id: "applyLibraryMembership",
    path: "/apply-library-membership/:id/payment",
    exact: true,
    component: PackagePayment,
    meta: {
      appLayout: true,
      privateRoute: true,
    },
  },

  {
    id: "privacy-policy",
    path: "/privacy&policy",
    exact: true,
    component: Privacy,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },
];

export default appRoutes;

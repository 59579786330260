import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = (props) => {
  const { children, ...other } = props;
  const loginStatus = useSelector((state) => state.user.loginStatus);

  if (!loginStatus) return <Navigate to="/login" replace />;
  return <Fragment {...other}>{children}</Fragment>;
};

export default PrivateRoute;

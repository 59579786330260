import getApiResponse from "../index";

const getCategoriesForDropdownApi = async () => {
  const result = await getApiResponse({
    url: `/category`,
    method: "get",
    otherParams: {
      forDropDown: true,
    },
  });

  if (result.APIFailed) return null;
  return result.data;
};

export default getCategoriesForDropdownApi;

import { useState } from "react";
import { Menu, Search } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import NavigationDrawer from "./NavigationDrawer";
import logo from "../../assets/logo/logo.png";

const MobileHeader = () => {
  const navigate = useNavigate();
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const searchClickHandler = () => {
    navigate("/search", {
      state: { searchQuery: "" },
    });
  };

  return (
    <>
      <header className="fixed top-0 z-10 w-full max-w-[1290px] mx-auto shadow bg-white px-3 md:px-8">
        <div className="flex justify-between items-center py-2">
          <Link to="/">
            <img src={logo} alt="logo" className="w-[75px]" />
          </Link>
          <div className="flex items-center gap-4">
            <Search
              size={22}
              className="cursor-pointer text-gray-800"
              onClick={searchClickHandler}
            />

            <Menu
              size={25}
              className="cursor-pointer text-gray-800"
              onClick={() => setIsDrawerOpened(true)}
            />
          </div>
        </div>
      </header>

      <NavigationDrawer isOpen={isDrawerOpened} setIsOpen={setIsDrawerOpened} />
    </>
  );
};

export default MobileHeader;

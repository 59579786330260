import { Fragment, useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { ChevronDown, LogOut, Search, User, X } from "react-feather";
import logo from "../../assets/logo/logo.png";
import navigationMenus from "../../navigation/navigationMenu";
import CategoryDropdown from "./CategoryDropdown";
import { resetLogin } from "../../redux/slices/userSlice";

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSearchDisplayed, setIsSearchDisplayed] = useState(false);

  const user = useSelector((state) => state.user);
  const [isCategoryDropdownOpened, setIsCategoryDropdownOpened] =
    useState(false);

  const searchInputRef = useRef();
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    if (isSearchDisplayed) searchInputRef.current.focus();
  }, [isSearchDisplayed]);

  const handleLogout = () => {
    dispatch(resetLogin());
  };

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    navigate("/search", {
      state: { searchQuery: searchKey },
    });
    setIsSearchDisplayed(false);
  };

  const categoryAll = () => {
    setIsCategoryDropdownOpened(false);
    navigate("/books/category/all");
  };

  return (
    <header className="w-full fixed top-[0] z-10 shadow-sm">
      <div className="flex gap-5 justify-between items-center py-4 w-full  max-w-[1290px] mx-auto top-0 px-8 bg-white">
        <Link to="/">
          <img src={logo} alt="logo" className="w-[100px]" />
        </Link>

        <div className="flex gap-10 lg:gap-20">
          {navigationMenus.map((menu) => {
            if (menu.id === "category") {
              return (
                <Fragment key={menu.id}>
                  <Link
                    to=""
                    className={cn(
                      "text-[15px] font-semibold uppercase pb-1 cursor-pointer tracking-[1.6px] flex gap-1 items-center",
                      {
                        "border-b-2 border-primary text-primary":
                          pathname === menu.path,
                        "text-gray-600": pathname !== menu.path,
                      }
                    )}
                    onMouseOver={(e) => {
                      e.stopPropagation();
                      setIsCategoryDropdownOpened(!isCategoryDropdownOpened);
                    }}
                    // onMouseOut={(e) => {
                    //   e.stopPropagation();
                    //   setIsCategoryDropdownOpened(false);
                    // }}
                    onClick={categoryAll}
                  >
                    {menu.name}
                    <span>
                      <ChevronDown className="text-gray-600" size={20} />
                    </span>
                  </Link>
                  <CategoryDropdown
                    isOpen={isCategoryDropdownOpened}
                    setIsOpen={setIsCategoryDropdownOpened}
                    className="fixed top-[82px]"
                  />
                </Fragment>
              );
            }

            return (
              <Link
                key={menu.id}
                to={menu.path}
                className={cn(
                  "text-[15px] font-semibold uppercase pb-[2px] tracking-[1.6px]",
                  {
                    "border-b-[2px] border-primary text-primary":
                      pathname === menu.path,
                    "text-gray-600": pathname !== menu.path,
                  }
                )}
              >
                {menu.name}
              </Link>
            );
          })}
        </div>

        <div>
          {user.loginStatus ? (
            <div className="flex items-center gap-5">
              <div className="relative">
                <Search
                  className="cursor-pointer text-gray-500"
                  onClick={() => setIsSearchDisplayed(true)}
                />
                <form
                  className={cn("absolute top-[-10px] right-0", {
                    hidden: !isSearchDisplayed,
                    block: isSearchDisplayed,
                  })}
                  onSubmit={searchSubmitHandler}
                >
                  <X
                    size={18}
                    className="absolute top-[11px] right-[8px] cursor-pointer text-neutral-700 hover:text-neutral-900"
                    onClick={() => {
                      setIsSearchDisplayed(false);
                      setSearchKey("");
                    }}
                  />
                  <input
                    ref={searchInputRef}
                    type="text"
                    placeholder="Search books by name , author name or ISBN"
                    className="w-[740px] pl-3 pr-8 py-2 rounded-md border outline-0 text-neutral-800"
                    value={searchKey}
                    onChange={(e) => setSearchKey(e.target.value)}
                    onMouseLeave={() => setIsSearchDisplayed(false)}
                  />
                </form>
              </div>

              <Menu as="div" className="relative">
                <Menu.Button className="flex w-full justify-center items-center gap-2">
                  <div className="rounded-full p-2 bg-gray-200">
                    <User className="text-gray-500" />
                  </div>
                  <p className="text-gray-500">
                    {user.user.firstName} {user.user.lastName}
                  </p>
                  <ChevronDown size={20} className="text-gray-500" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-[350px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="p-3">
                      <Menu.Item>
                        <Link
                          to="/account/profile/customer-order"
                          className={cn(
                            "flex items-center gap-5 w-full p-2 rounded-sm"
                          )}
                        >
                          <User size={23} className="text-gray-600" />
                          <p className="text-lg font-semibold text-gray-700">
                            Profile
                          </p>
                        </Link>
                      </Menu.Item>

                      <Menu.Item>
                        <Link
                          to="/"
                          className={cn(
                            "mt-3 flex items-center gap-5 w-full p-2 rounded-sm"
                          )}
                          onClick={handleLogout}
                        >
                          <LogOut size={23} className="text-primary" />
                          <p className="text-lg font-semibold text-gray-700">
                            Logout
                          </p>
                        </Link>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          ) : (
            <div className="flex items-center gap-8">
              <div className="relative">
                <Search
                  className="cursor-pointer text-gray-500"
                  onClick={() => setIsSearchDisplayed(true)}
                />
                <form
                  className={cn("absolute top-[-10px] right-0", {
                    hidden: !isSearchDisplayed,
                    block: isSearchDisplayed,
                  })}
                  onSubmit={searchSubmitHandler}
                >
                  <X
                    size={18}
                    className="absolute top-[11px] right-[8px] cursor-pointer text-neutral-700 hover:text-neutral-900"
                    onClick={() => {
                      setIsSearchDisplayed(false);
                      setSearchKey("");
                    }}
                  />
                  <input
                    ref={searchInputRef}
                    type="text"
                    placeholder="Search books by name, author name or ISBN"
                    className="w-[740px] pl-3 pr-8 py-2 rounded-md border outline-0 text-neutral-800"
                    value={searchKey}
                    onChange={(e) => setSearchKey(e.target.value)}
                    onMouseLeave={() => setIsSearchDisplayed(false)}
                  />
                </form>
              </div>
              <Link to="/register" className="font-medium text-gray-500">
                Register
              </Link>
              <Link
                to="/login"
                className="px-6 py-2 rounded-md font-medium bg-primary text-white"
              >
                Login
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;

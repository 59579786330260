import { useState } from "react";
import { useSelector } from "react-redux";
import logo from "../../assets/logo/logo.png";
import { MapPin, Mail, Phone } from "react-feather";
import CreateBookModal from "../createBookModal/CreateBookModal";
import toast from "react-hot-toast";
import googlePlay from "../../assets/images/footerImages/google-play.png";
import applePlay from "../../assets/images/footerImages/app-store.png";

const followUsLink = [
  { name: "Facebook", link: "https://www.facebook.com/BookLifterNep" },
  // { name: "Twitter", link: "https://twitter.com/" },
  // { name: "Instagram", link: "https://www.instagram.com/" },
  // { name: "YouTube", link: "https://www.youtube.com/" },
];

const Footer = () => {
  const isUserLogin = useSelector((state) => state?.user?.loginStatus);
  const [isCreateBookModalOpened, setIsCreateBookModalOpened] = useState(false);

  const registerBookHandler = () => {
    if (isUserLogin) setIsCreateBookModalOpened(true);
    else toast.error("Login is required to register the book");
  };

  return (
    <>
      <footer className="bg-[#F8F7F7]">
        <div className="w-full max-w-[1290px] px-3 md:px-8 mx-auto flex flex-col space-y-10 md:flex-row md:space-y-0 space-x-3 py-8 justify-between">
          <div className="grow md:max-w-[350px] flex flex-col text-left">
            <img src={logo} alt="logo" width={100} />
            <p className="mt-5 text-sm text-textColor">
              <span className="font-[600]">Book Lifter</span> is a
              partnership-based library which includes the cooperation of about
              50,000 librarians and users who will have access to books from all
              over the world. Readers will have access to a wide selection of
              their favorite books all in one platform.
            </p>
          </div>

          <div className="flex-none  md:min-w-[200px]">
            <h6 className="font-semibold text-textColor">Follow us</h6>
            {followUsLink.map((link, index) => {
              return (
                <a
                  href={link.link}
                  key={index}
                  target="_blank"
                  className="block mt-2 text-textColor text-sm"
                >
                  <p>{link.name}</p>
                </a>
              );
            })}
            <div
              className="block mt-2 text-sm cursor-pointer text-textColor"
              onClick={registerBookHandler}
            >
              Register your book
            </div>
          </div>

          <div className="flex-none md:min-w-[200px]">
            <h6 className="font-semibold text-textColor">Contact</h6>
            <div className="flex items-center mt-2">
              <span>
                <MapPin height={14.5} />
              </span>
              <p className="ml-1 text-textColor text-sm">Pokhara, 33700</p>
            </div>
            <div className="flex items-center mt-2">
              <span>
                <Mail height={14.5} />
              </span>
              <p className="ml-1 text-textColor text-sm">
                booklifter@gmail.com
              </p>
            </div>
            <div className="flex items-center mt-2">
              <span>
                <Phone height={14.5} />
              </span>
              <p className="ml-1 text-textColor text-sm">+977 9856031647</p>
            </div>
            <div className="flex items-center mt-2">
              <span>
                <Phone height={14.5} />
              </span>
              <p className="ml-1 text-textColor text-sm">+977 9851177441</p>
            </div>
          </div>

          <div>
            <h6 className="font-semibold text-textColor">Mobile Apps</h6>
            <div className="flex flex-col gap-2 mt-2">
              <a
                href="https://play.google.com/store/apps/details?id=com.booklifter.app"
                target="_blank"
              >
                <img src={googlePlay} alt="google play" className="w-[150px]" />
              </a>
              {/* <img src={applePlay} alt="apple play" className="w-[150px]" /> */}
            </div>
          </div>
        </div>

        <div className="bg-grey-700 text-center py-3 bg-neutral-400 px-4">
          <p className="normal-text text-neutral-700">
            Copyright &copy; {new Date().getFullYear()}. All right Reserved.
            Developed by BLACK TECH.
          </p>
        </div>
      </footer>

      <CreateBookModal
        isOpen={isCreateBookModalOpened}
        closeAddBookModal={() => setIsCreateBookModalOpened(false)}
      />
    </>
  );
};

export default Footer;

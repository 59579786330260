import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  packageDetails: {},
};

export const libraryMembershipSlice = createSlice({
  name: "libraryMembership",
  initialState,
  reducers: {
    setLibraryMembershipDetails: (state, action) => {
      state.packageDetails = { ...state.packageDetails, ...action.payload };
    },

    clearLibraryMembershipDetails: (state) => {
      state.packageDetails = {};
    },
  },
});

export const { setLibraryMembershipDetails, clearLibraryMembershipDetails } =
  libraryMembershipSlice.actions;

export default libraryMembershipSlice.reducer;

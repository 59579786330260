import { useState, useEffect } from "react";
import Modal from "../modal/Modal";
import ImageDropzone from "../imageDropzone/ImageDropzone";
import getCategoriesForDropdownApi from "../../services/category/getCategoriesFroDropdownApi";
import getPublisherForDropdownApi from "../../services/publisher/getPublisherForDropdownApi";
import getLanguagesForDropdownApi from "../../services/language/getLanguagesForDropdownApi";
import requestBookApi from "../../services/books/requestBookApi";
import ErrorMessage from "../common/ErrorMessage";

const styles = {
  detailContainer:
    "flex lg:space-x-5 flex-col lg:flex-row space-y-8 lg:space-y-0",
};

function verifyBookDetails(props) {
  const {
    image,
    bookName,
    category,
    authorName,
    publisher,
    publishedYear,
    language,
    description,
  } = props;

  if (!image) {
    return { isVerified: false, message: "Please select the book image." };
  }
  if (!bookName.trim()) {
    return { isVerified: false, message: "Please enter the book name." };
  }
  if (!category) {
    return { isVerified: false, message: "Please select a category." };
  }
  if (!authorName.trim()) {
    return { isVerified: false, message: "Please enter the author name." };
  }
  if (!publisher.trim()) {
    return { isVerified: false, message: "Please select a publisher." };
  }
  if (!publishedYear) {
    return { isVerified: false, message: "Please select a published year." };
  }

  if (!language) {
    return { isVerified: false, message: "Please select a language." };
  }

  if (!description.trim()) {
    return { isVerified: false, message: "Please enter the description." };
  }

  return { isVerified: true, message: "Data verified." };
}

const CreateBookModal = ({ isOpen, closeAddBookModal }) => {
  const [categoryList, setCategoryList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);

  const [image, setImage] = useState(null);
  const [bookName, setBookName] = useState("");
  const [category, setCategory] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [isbn, setIsbn] = useState("");
  const [edition, setEdition] = useState("");
  const [publisher, setPublisher] = useState("");
  const [publishedYear, setPublishedYear] = useState("");
  const [language, setLanguage] = useState("");
  const [description, setDescription] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const setRequiredData = async () => {
    const categoryResData = await getCategoriesForDropdownApi();
    const publisherResData = await getPublisherForDropdownApi();
    const languageResData = await getLanguagesForDropdownApi();

    setCategoryList(categoryResData?.data || []);
    setPublisherList(publisherResData?.data || []);
    setLanguageList(languageResData?.data || []);
  };

  useEffect(() => {
    setRequiredData();
  }, []);

  useEffect(() => {
    if (isOpen) return;
    setImage(null);
    setBookName("");
    setCategory("");
    setAuthorName("");
    setIsbn("");
    setEdition("");
    setPublisher("");
    setPublishedYear(null);
    setLanguage("");
    setDescription("");
  }, [isOpen]);

  useEffect(() => {
    setErrorMsg("");
  }, [
    image,
    bookName,
    category,
    authorName,
    publisher,
    publishedYear,
    language,
    edition,
    isbn,
    description,
  ]);

  const handleModalClose = () => {
    closeAddBookModal();
    setImage(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { isVerified, message } = verifyBookDetails({
      image,
      bookName,
      category,
      authorName,
      publisher,
      publishedYear,
      language,
      description,
    });

    if (!isVerified) {
      setErrorMsg(message);
      return;
    }

    setIsSubmitted(true);

    const formData = new FormData();
    formData.append("name", bookName);
    formData.append("categories", category);
    formData.append("hardCoverPrice", 0);
    formData.append("softCoverPrice", 0);
    formData.append("author", authorName);
    formData.append("isbn", isbn);
    formData.append("edition", edition);
    formData.append("publisher", publisher);
    formData.append("publishedYear", publishedYear);
    formData.append("language", language);
    formData.append("coverImage", image[0]);
    formData.append("description", description);

    const bookResData = await requestBookApi(formData);

    setIsSubmitted(false);
    if (!bookResData) return;
    setImage(null);
    setBookName("");
    setCategory("");
    setAuthorName("");
    setIsbn("");
    setEdition("");
    setPublisher("");
    setPublishedYear(null);
    setLanguage("");
    setDescription("");

    closeAddBookModal();
  };

  return (
    <Modal isOpen={isOpen} closeModal={handleModalClose}>
      <form onSubmit={handleSubmit} className="md:py-2 md:px-4">
        <div className="text-center">
          <h4 className="main-header">Register Your Book</h4>
          <p className="text-textColor normal-text">
            If you need any help or encounter difficulties, don't hesitate to
            contact us (+977 9856031647)
          </p>
        </div>

        <div className="w-full max-w-[850px] flex flex-col space-y-8 mt-12">
          {errorMsg && <ErrorMessage errorMsg={errorMsg} />}

          <div>
            <label className="inputLabel">
              Upload Book Cover Image<span className="text-primary">*</span>
            </label>
            <ImageDropzone image={image} setImage={setImage} />
          </div>

          <div className={styles.detailContainer}>
            <div className="w-full">
              <label className="inputLabel">
                Book Name <span className="text-primary">*</span>
              </label>
              <input
                style={{ fontFamily: "Poppins Light" }}
                className="inputField"
                type="text"
                placeholder="Enter book name"
                value={bookName}
                onChange={(e) => setBookName(e.target.value)}
              />
            </div>

            <div className="w-full">
              <label className="inputLabel">
                Category <span className="text-primary">*</span>
              </label>

              <select
                style={{ fontFamily: "Poppins Light" }}
                className="inputField bg-white"
                value={category || "0"}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="0" disabled hidden>
                  Select a category
                </option>
                {categoryList.map((item) => (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={styles.detailContainer}>
            <div className="w-full">
              <label className="inputLabel">
                Author Name <span className="text-primary">*</span>
              </label>
              <input
                style={{ fontFamily: "Poppins Light" }}
                className="inputField"
                type="text"
                placeholder="Enter author name"
                value={authorName}
                onChange={(e) => setAuthorName(e.target.value)}
              />
            </div>

            <div className="w-full">
              <label className="inputLabel">ISBN Number</label>
              <input
                style={{ fontFamily: "Poppins Light" }}
                className="inputField"
                type="text"
                placeholder="enter ISBN"
                value={isbn}
                onChange={(e) => setIsbn(e.target.value)}
              />
            </div>
            <div className="w-full">
              <label className="inputLabel">Edition</label>
              <input
                style={{ fontFamily: "Poppins Light" }}
                className="inputField"
                type="text"
                placeholder="Enter Edition"
                value={edition}
                onChange={(e) => setEdition(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.detailContainer}>
            <div className="w-full">
              <label className="inputLabel">
                Publisher <span className="text-primary">*</span>
              </label>
              <select
                style={{ fontFamily: "Poppins Light" }}
                className="inputField bg-white"
                value={publisher || 0}
                onChange={(e) => setPublisher(e.target.value)}
              >
                <option value="0" disabled hidden>
                  Select a publisher
                </option>
                {publisherList.map((item) => (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-full">
              <label className="inputLabel">
                Published Year <span className="text-primary">*</span>
              </label>
              <input
                style={{ fontFamily: "Poppins Light" }}
                className="inputField"
                type="date"
                placeholder="Published Year"
                value={publishedYear}
                onChange={(e) => setPublishedYear(e.target.value)}
              />
            </div>

            <div className="w-full">
              <label className="inputLabel">
                Language <span className="text-primary">*</span>
              </label>
              <select
                style={{ fontFamily: "Poppins Light" }}
                className="inputField bg-white"
                value={language || 0}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value="0" disabled hidden>
                  Select a language
                </option>
                {languageList.map((item) => (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="w-full">
            <label className="inputLabel">
              Description <span className="text-primary">*</span>
            </label>
            <textarea
              className="inputField w-full"
              rows={3}
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-end space-x-5 mt-8">
          <button
            type="button"
            className="transparentButton"
            onClick={() => handleModalClose()}
          >
            Cancel
          </button>
          <button type="submit" className="primaryButton">
            {isSubmitted ? "Registering..." : "Register Book"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateBookModal;

import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import useOnClickOutsize from "../../utils/useOnClickOutsize";
import getCategoriesApi from "../../services/category/getCategoriesApi";
import defaultBookImage from "../../assets/images/books/book.png";
import { Link } from "react-router-dom";

const CategoryDropdown = (props) => {
  const { className = "", isOpen, setIsOpen } = props;
  const cardRef = useRef(null);

  const [categoryList, setCategoryList] = useState([]);

  useOnClickOutsize(cardRef, (value) => {
    setIsOpen(value);
  });

  const setCategories = async () => {
    const categoriesResData = await getCategoriesApi({ page: 1, count: 20 });
    setCategoryList(categoriesResData.data.docs || []);
  };

  useEffect(() => {
    setCategories();
  }, []);

  const closeDropdown = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  return (
    <div ref={cardRef} className={cn("w-full mx-auto", className)}>
      <div
        className={cn(
          "p-8 rounded-md shadow bg-white z-10 overflow-hidden",
          `max-w-[900px]`,

          {
            hidden: !isOpen,
            block: isOpen,
          }
        )}
      >
        <div className="flex">
          <div className="w-full">
            <div className="grow grid grid-cols-2">
              {categoryList.map((category, index) => (
                <Link
                  key={category._id}
                  to={`/books/category/${category._id}`}
                  className={cn(
                    "text-[16px] font-medium capitalize transition-all text-[#667085] hover:pl-1 hover:text-primary",
                    {
                      "mt-3": index !== 0 && index !== 1,
                    }
                  )}
                  onClick={closeDropdown}
                >
                  {category.name}
                </Link>
              ))}
            </div>

            <div className="mt-8 flex justify-center">
              <Link
                to="/books/category/all"
                className="font-medium text-center text-[#040714] text-lg hover:text-primary"
                onClick={closeDropdown}
              >
                View all Books
              </Link>
            </div>
          </div>
          <div className="flex-none hidden md:block">
            <div className="flex justify-end">
              <img
                src={defaultBookImage}
                alt="default book"
                className="w-full max-w-[250px]"
              />
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default CategoryDropdown;

import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import { Link } from "react-router-dom";
import { LogOut, User, X } from "react-feather";
import cn from "classnames";
import navigationMenus from "../../navigation/navigationMenu";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/logo/logo.png";
import { resetLogin } from "../../redux/slices/userSlice";

const NavigationDrawer = (props) => {
  const { isOpen, setIsOpen } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    dispatch(resetLogin());
    toggleDrawer();
  };

  return (
    <Drawer open={isOpen} onClose={toggleDrawer} direction="right" size={320}>
      <div className="px-8">
        <div className="flex justify-end mt-4">
          <X
            size={27}
            className="text-primary cursor-pointer"
            onClick={toggleDrawer}
          />
        </div>

        <div className="px-2 mt-8">
          {navigationMenus.map((menu) => (
            <div key={menu.id} className="mt-8">
              <Link
                to={menu.path}
                className="text-gray-600 font-medium capitalize"
                onClick={toggleDrawer}
              >
                {menu.name}
              </Link>
            </div>
          ))}

          <div className="mt-8">
            <Link
              to="/search"
              className="text-gray-600 font-medium capitalize"
              onClick={toggleDrawer}
            >
              Search
            </Link>
          </div>
        </div>

        <div className="mt-12">
          {user.loginStatus ? (
            <div>
              <div className="flex items-center gap-5">
                <div>
                  <div className="flex items-center gap-3">
                    <div className="rounded-full p-2 bg-gray-200">
                      <User className="text-gray-500" />
                    </div>
                    <div className="max-w-[150px]">
                      <p className="text-gray-500">
                        {user.user.firstName} {user.user.lastName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <Link
                  to="/account/profile/customer-order"
                  className={cn(
                    "flex items-center gap-5 w-full p-2 rounded-sm"
                  )}
                  onClick={toggleDrawer}
                >
                  <User size={20} className="text-gray-600" />
                  <p className="text-sm font-semibold text-gray-600">Profile</p>
                </Link>

                <Link
                  to="/"
                  className={cn(
                    "mt-2 flex items-center gap-5 w-full p-2 rounded-sm"
                  )}
                  onClick={handleLogout}
                >
                  <LogOut size={20} className="text-primary" />
                  <p className="text-sm font-semibold text-gray-600">Logout</p>
                </Link>
              </div>
            </div>
          ) : (
            <div className="flex justify-center">
              <div className="flex items-center gap-8">
                <Link
                  to="/register"
                  className="font-semibold text-gray-500"
                  onClick={toggleDrawer}
                >
                  Register
                </Link>
                <Link
                  to="/login"
                  className="px-6 py-2 rounded-md font-semibold bg-primary text-white"
                  onClick={toggleDrawer}
                >
                  Login
                </Link>
              </div>
            </div>
          )}
        </div>

        <div className="mt-16 flex justify-center">
          <div>
            <img src={logo} alt="logo" className="w-[150px]" />
            <p className="mt-5 text-gray-600 font-semibold">
              www.book<span className="text-primary">lifter</span>.com
            </p>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default NavigationDrawer;

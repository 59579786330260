import { lazy } from "react";

const Login = lazy(() => import("../app/auth/Login"));
const Register = lazy(() => import("../app/auth/Register"));
const RegistrationOtp = lazy(() => import("../app/auth/RegistrationOtp"));
const ForgotPassword = lazy(() => import("../app/auth/ForgotPassword"));
const ForgotPasswordOtpVerification = lazy(() =>
  import("../app/auth/ForgotPasswordOtpVerification")
);

const authRoutes = [
  {
    id: "login",
    path: "/login",
    exact: true,
    component: Login,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },

  {
    id: "register",
    path: "/register",
    exact: true,
    component: Register,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },

  {
    id: "registrationOtp",
    path: "/register/verify-otp",
    exact: true,
    component: RegistrationOtp,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },

  {
    id: "registrationOtp",
    path: "/forgot-password",
    exact: true,
    component: ForgotPassword,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },

  {
    id: "forgotPasswordOtpVerification",
    path: "/forgot-password/verify-otp",
    exact: true,
    component: ForgotPasswordOtpVerification,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },
];

export default authRoutes;

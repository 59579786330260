import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Plus, XCircle } from "react-feather";

const ImageDropzone = (props) => {
  const { image, setImage, label = "Upload the cover image of book." } = props;

  const onDrop = useCallback((acceptedFiles) => {
    setImage(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div
        className={`mt-1 border px-5 py-3 rounded-md border-dashed outline-none flex items-center ${
          image ? "flex justify-between items-center" : "flex space-x-5"
        }`}
        {...getRootProps()}
      >
        {image ? (
          <div>
            <img
              className="h-16 w-12 object-contain"
              src={image && URL.createObjectURL(image[0])}
              alt="Book Cover"
            />
          </div>
        ) : (
          <div className="bg-[#F8F7F7] h-16 w-12 flex items-center justify-center rounded-sm">
            <Plus size={25} className="text-primary" />
          </div>
        )}
        {image ? (
          <div onClick={() => setImage(null)}>
            <XCircle className="text-primary cursor-pointer" />
          </div>
        ) : (
          <div>
            <input className="outline-none" {...getInputProps()} />
            {isDragActive ? (
              <h6>Drop the files here ...</h6>
            ) : (
              <div>
                <h6>Upload Image</h6>
                <p className="sub-text text-textColor">{label}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageDropzone;

import { Plus } from "react-feather";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import MobileHeader from "../components/header/MobileHeader";
import useWindowSize from "../utils/useWindowSize";
import CreateBookModal from "../components/createBookModal/CreateBookModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const AppLayout = (props) => {
  const { children } = props;
  const { width } = useWindowSize();
  const isUserLogin = useSelector((state) => state?.user?.loginStatus);

  const [isCreateBookModalOpened, setIsCreateBookModalOpened] = useState(false);

  const registerBookHandler = () => {
    if (isUserLogin) setIsCreateBookModalOpened(true);
    else toast.error("Login is required to register the book");
  };

  return (
    <>
      <div>
        {width > 1024 ? <Header /> : <MobileHeader />}
        <div className="min-h-[100vh] max-w-[1290px] mx-auto pt-20 lg:pt-32 px-3 md:px-8">
          {children}
        </div>
        <div className="fixed bottom-[30px] right-[20px] z-10 select-none">
          <div
            className="flex items-center gap-2 py-[6px] md:py-[8px] px-3 md:px-4 rounded-full cursor-pointer text-white bg-primary hover:text-neutral-800 hover:bg-yellow-300"
            onClick={registerBookHandler}
          >
            <Plus size={18} />
            <p className="small-text font-normal tracking-wider md:text-[18px]">
              Register Your Books
            </p>
          </div>
        </div>
        <Footer />
      </div>

      <CreateBookModal
        isOpen={isCreateBookModalOpened}
        closeAddBookModal={() => setIsCreateBookModalOpened(false)}
      />
    </>
  );
};

export default AppLayout;

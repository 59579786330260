import cn from "classnames";

const ErrorMessage = (props) => {
  const { errorMsg, className = "" } = props;
  return (
    <p
      className={cn(
        "text-gray-600 bg-[#F9BBBE] p-2 rounded-md font-medium",
        className
      )}
    >
      {errorMsg}
    </p>
  );
};

export default ErrorMessage;

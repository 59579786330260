import getApiResponse from "../index";

const requestBookApi = async (data) => {
  const result = await getApiResponse({
    url: `/requestBook/`,
    method: "post",
    data,
  });

  if (result.APIFailed) return null;
  return result.data;
};

export default requestBookApi;
